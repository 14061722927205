import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import { PaymentEventBus } from "@/core/lib/payment/payment.lib";
import {
  PUT,
  QUERY,
  POST,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      payment: 0,
      paymentModeList: [],
      invoiceList: [],
      backForce: false,
      customFieldDialog: false,
      managePaymentModeDialog: false,
      disableNotification: true,
      paymentMinDate: null,
      customFields: new Array(),
      paymentCustomFields: new Array(),
      pageLoading: false,
      barcodeDialog: false,
      isUpdateMode: false,
      MandatoryPaymentDate: false,
      barcodeSetting: {},
      customerObject: {},
      attention: null,
      paymentCreate: {
        invoice: 0,
        barcode: null,
        reference: null,
        payment_mode: 0,
      },
      customer: {},
      property: {},
      billing: {},
      updateData: {
        PaymentAttachment: {},
        PaymentInformation: {
          pending: "0.00",
          amount: "0.00",
          transaction_id: null,
        },
      },
      noteAttachment: {},
      paymentInformation: {},
      contact_person: {},
    };
  },
  methods: {
    searchInvoice(item, queryText) {
      return (
        item.title
          .toString()
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.pending_amount
          .toString()
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.barcode
          .toString()
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    openInvoice() {
      this.$refs["invoice"].$refs.input.focus();
    },
    selectInvoice() {
      const _this = this;
      _this.customer = {};
      _this.property = {};
      _this.billing = {};
      _this.contact_person = {};
      let invoice = _this.lodash.toSafeInteger(_this.paymentCreate.invoice);
      if (invoice > 0) {
        let index = _this.lodash.findIndex(_this.invoiceList, function (row) {
          return row.id == invoice;
        });
        if (
          index >= 0 &&
          _this.lodash.isEmpty(_this.invoiceList[index]) === false
        ) {
          _this.attention = _this.invoiceList[index].attention;
          _this.customer = _this.invoiceList[index].customer;
          _this.property = _this.invoiceList[index].property;
          _this.billing = _this.invoiceList[index].billing;
          _this.contact_person = _this.invoiceList[index].contact_person;
          _this.updateData.PaymentInformation.pending =
            _this.invoiceList[index].pending_amount;
        }
      }
    },
    setPaymentDate(param) {
      this.paymentCreate.payment_date = param;
    },
    updateBarcodeSetting() {
      this.barcodeDialog = false;
    },
    getAttributes(attributes) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "payment/options/" + _this.payment,
          data: {
            attribute: attributes,
          },
        })
        .then((response) => {
          if (_this.lodash.isEmpty(response.data) === false) {
            if (_this.lodash.isEmpty(response.data.invoices) === false) {
              _this.invoiceList = response.data.invoices;
            }
            if (_this.lodash.isEmpty(response.data.custom_field) === false) {
              _this.customFields = response.data.custom_field;
            }
            if (_this.lodash.isEmpty(response.data.payment_modes) === false) {
              _this.paymentModeList = response.data.payment_modes;
            }
            if (response.data.barcode) {
              _this.paymentCreate.barcode = response.data.barcode;
            }

            if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
              _this.barcodeSetting = response.data.barcode_setting;

              _this.updateData.PaymentAttachment = {
                admin_remark: _this.barcodeSetting.admin_note,
                client_remark: _this.barcodeSetting.client_note,
              };
            }

            if (_this.lodash.isEmpty(response.data.payment) === false) {
              _this.paymentCreate.invoice = response.data.payment.invoice;
              _this.paymentCreate.barcode = response.data.payment.barcode;
              _this.paymentCreate.reference = response.data.payment.reference;
              _this.paymentCreate.payment_mode =
                response.data.payment.payment_mode;
              _this.updateData.PaymentAttachment = {
                admin_remark: response.data.payment.admin_note,
                client_remark: response.data.payment.client_note,
                documents: response.data.payment.documents,
              };
              _this.updateData.PaymentInformation.amount =
                response.data.payment.amount;
              _this.updateData.PaymentInformation.transaction_id =
                response.data.payment.transaction_id;
              _this.selectInvoice();

              _this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Payment", route: "payment" },
                { title: "Update" },
                { title: _this.paymentCreate.barcode },
              ]);
            }
          }
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    onSubmit(type) {
      const _this = this;
      if (!_this.$refs.paymentForm.validate()) {
        return false;
      }

      _this.$store.dispatch(CLEAR_ERROR, {});
      const formData = {
        action: typeof type == "string" ? type : null,
        reference: _this.paymentCreate.reference || null,
        invoice: _this.lodash.toSafeInteger(_this.paymentCreate.invoice) || 0,
        amount: _this.lodash.toNumber(_this.paymentInformation.received) || 0.0,
        payment_date: _this.paymentCreate.payment_date || null,
        admin_note: _this.noteAttachment.admin_notes || null,
        client_note: _this.noteAttachment.client_notes || null,
        transaction_id: _this.paymentInformation.transaction_id || null,
        payment_mode:
          _this.lodash.toSafeInteger(_this.paymentCreate.payment_mode) || 0,
        documents: _this.noteAttachment.documents || [],
        custom_field: _this.customFields || [],
      };

      _this.formLoading = true;

      let REQUEST_TYPE = POST;
      let REQUEST_URL = "payment";
      if (_this.payment && _this.payment > 0) {
        REQUEST_TYPE = PUT;
        REQUEST_URL = "payment/" + _this.payment;
      }
      _this.$store
        .dispatch(REQUEST_TYPE, { url: REQUEST_URL, data: formData })
        .then((response) => {
          _this.payment = response.data.id;
          _this.backForce = true;
          _this.$router.push(
            _this.getDefaultRoute("payment.detail", {
              params: {
                id: _this.payment,
              },
            })
          );
        })
        .catch((error) => {
          _this.backForce = false;
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Payment",
        route: "payment",
      },
      {
        title: "Create",
      },
    ]);

    _this.paymentMinDate = moment().format("YYYY-MM-DD");

    _this.getAttributes([
      "invoices",
      "barcode",
      "barcode_setting",
      "custom_field",
      "payment_modes",
    ]);
  },
  beforeDestroy() {
    /*NoteAttachmentEventBus.$off("update:notes-attachment");
    PaymentEventBus.$off("update:payment-information");*/
  },
  created() {
    const _this = this;
    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      _this.noteAttachment = argument;
    });

    PaymentEventBus.$on("update:payment-information", (argument) => {
      _this.paymentInformation = argument;
    });

    if (_this.$route.name == "payment-update") {
      _this.payment = _this.$route.params.id;
      if (!_this.payment) {
        _this.$router.go(-1);
      }
      _this.isUpdateMode = true;
    }
  },
  computed: {
    isPageLoading() {
      return this.pageLoading || this.formLoading;
    },
    CityBillingName() {
      return this.lodash.isEmpty(this.billing.city) === false
        ? this.billing.city.name
        : null;
    },
    StateBillingName() {
      return this.lodash.isEmpty(this.billing.state) === false
        ? this.billing.state.name
        : null;
    },
    CountryBillingName() {
      return this.lodash.isEmpty(this.billing.country) === false
        ? this.billing.country.name
        : null;
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
  },
};
