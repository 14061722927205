<template>
  <div class="custom-field d-flex">
    <template v-if="field.field_type == 'dropdown'">
      <v-select
        :items="field.list"
        dense
        filled
        outlined
        :disabled="pageLoading"
        v-model.trim="field['value']"
        :rules="
          field.mandatory
            ? [() => !!field['value'] || field.field_name + ' is required']
            : []
        "
        :label="field.field_name"
        hide-details
        color="cyan"
        item-color="cyan"
      ></v-select>
    </template>
    <template v-if="field.field_type == 'text'">
      <v-text-field
        dense
        filled
        :disabled="pageLoading"
        v-model.trim="field['value']"
        :rules="
          field.mandatory
            ? [() => !!field['value'] || field.field_name + ' is required']
            : []
        "
        :label="field.field_name"
        color="cyan"
        outlined
        hide-details
      ></v-text-field>
    </template>
    <template v-if="field.field_type == 'textarea'">
      <v-textarea
        auto-grow
        dense
        :disabled="pageLoading"
        v-model.trim="field['value']"
        :rules="
          field.mandatory
            ? [() => !!field['value'] || field.field_name + ' is required']
            : []
        "
        :label="field.field_name"
        filled
        outlined
        row-height="30"
        color="cyan"
      ></v-textarea>
    </template>
    <template v-if="field.field_type == 'checkbox'">
      <template v-for="(row, index) in field.list">
        <v-checkbox
          :key="index"
          multiple
          :disabled="pageLoading"
          v-model.trim="field['value']"
          :rules="
            field.mandatory
              ? [() => !!field['value'] || row + ' is required']
              : []
          "
          :label="lodash.capitalize(row)"
          :value="row"
          color="cyan"
          class="mt-0 pr-2"
          hide-details
        ></v-checkbox>
      </template>
    </template>
    <template v-if="field.field_type == 'radio'">
      <v-radio-group
        class="pt-0 mt-0 mb-2"
        dense
        row
        :disabled="pageLoading"
        color="cyan"
        mandatory
        v-model.trim="field['value']"
      >
        <v-radio
          v-for="(row, index) in field.list"
          :key="index"
          :disabled="pageLoading"
          :label="lodash.capitalize(row)"
          :value="row"
          color="cyan"
        >
        </v-radio>
      </v-radio-group>
    </template>
    <template v-if="field.field_type == 'date'">
      <DatePicker
        :placeholder="field.field_name"
        :pageLoading.sync="pageLoading"
        :mandatory="field.mandatory"
        v-on:update:date-picker="updateDate"
      >
      </DatePicker>
    </template>
    <template v-if="field.field_type == 'date'">
      <Timepicker
        :placeholder="field.field_name"
        :pageLoading.sync="pageLoading"
        :mandatory="field.mandatory"
        v-on:update:time-picker="updateTime"
      >
      </Timepicker>
    </template>
  </div>
</template>

<script>
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import Timepicker from "@/view/pages/partials/Timepicker.vue";

export default {
  name: "line-items",
  props: {
    field: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    index: {
      type: Number,
      required: true,
      default: 0,
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateDate(param) {
      this.field["value"] = param;
    },
    updateTime(param) {
      this.field["value"] = param;
    },
  },
  components: {
    Timepicker,
    DatePicker,
  },
  watch: {
    field: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.value) {
          this.$emit("update:custom-field", {
            index: this.index,
            data: {
              default_value: param.default_value,
              field_name: param.field_name,
              field_type: param.field_type,
              custom_field: param.id,
              mandatory: param.mandatory,
              sort_order: param.sort_order,
              value: param.value,
            },
          });
        }
      },
    },
  },
  mounted() {
    if (this.field.value) {
      this.field["custom_field"] = this.lodash.toSafeInterger(
        this.field.custom_field
      );
    } else {
      this.field["value"] = this.field.default_value;
    }
  },
  data: () => {
    return {
      formValid: true,
    };
  },
};
</script>
