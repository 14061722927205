var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"commonDialog":_vm.fieldDialog,"dialogWidth":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("New Custom Field")]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"customFieldForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.moduleList,"dense":"","disabled":_vm.disableModule,"filled":"","outlined":"","label":"Module","item-color":"cyan","color":"cyan","item-text":"title","item-value":"value","hide-details":"","rules":[_vm.validateRules.required(_vm.customField.module, 'Module')]},model:{value:(_vm.customField.module),callback:function ($$v) {_vm.$set(_vm.customField, "module", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customField.module"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","color":"cyan","label":"Field name","outlined":"","hide-details":"","rules":[
                _vm.validateRules.required(_vm.customField.field_name, 'Field name'),
                _vm.validateRules.minLength(
                  _vm.customField.field_name,
                  'Field name',
                  1
                ),
                _vm.validateRules.maxLength(
                  _vm.customField.field_name,
                  'Field name',
                  100
                ) ]},model:{value:(_vm.customField.field_name),callback:function ($$v) {_vm.$set(_vm.customField, "field_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customField.field_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.customFieldList,"dense":"","filled":"","outlined":"","label":"Field type","item-color":"cyan","color":"cyan","hide-details":"","item-text":"title","item-value":"value","rules":[
                _vm.validateRules.required(_vm.customField.field_type, 'Module') ]},model:{value:(_vm.customField.field_type),callback:function ($$v) {_vm.$set(_vm.customField, "field_type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customField.field_type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","color":"cyan","label":"Default value","outlined":"","hide-details":"","rules":[
                _vm.validateRules.minLength(
                  _vm.customField.default_value,
                  'Field name',
                  1
                ),
                _vm.validateRules.maxLength(
                  _vm.customField.default_value,
                  'Field name',
                  100
                ) ]},model:{value:(_vm.customField.default_value),callback:function ($$v) {_vm.$set(_vm.customField, "default_value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customField.default_value"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","hide-details":"","label":"Mandatory","value":1,"color":"cyan"},model:{value:(_vm.customField.mandatory),callback:function ($$v) {_vm.$set(_vm.customField, "mandatory", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customField.mandatory"}})],1),(_vm.isValidList)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{ref:"listvalue",attrs:{"dense":"","filled":"","color":"cyan","label":"List Value","placeholder":"Just Type and hit enter","outlined":"","hide-details":"","rules":[
                _vm.validateRules.minLength(_vm.listValue, 'List', 1),
                _vm.validateRules.maxLength(_vm.listValue, 'List', 100) ]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter.apply(null, arguments)}},model:{value:(_vm.listValue),callback:function ($$v) {_vm.listValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"listValue"}})],1):_vm._e(),(_vm.customField.list.length > 0)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('perfect-scrollbar',{staticClass:"scroll custom-box-top-inner-shadow",staticStyle:{"max-height":"40vh","position":"static"},attrs:{"options":{ suppressScrollX: true }}},[_c('table',{staticClass:"width-100 font-size-16 custom-grey-border font-weight-500"},_vm._l((_vm.customField.list),function(field,index){return _c('tr',{key:index,class:{
                    'custom-border-bottom': _vm.customField.list[index + 1],
                  }},[_c('td',{staticClass:"py-2 px-4 custom-border-right",attrs:{"align":"left"}},[_vm._v(" "+_vm._s(field)+" ")]),_c('td',{staticClass:"py-2 px-4",attrs:{"align":"center"}},[_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":_vm.formLoading,"color":"red lighten-1","fab":"","small":""},on:{"click":function($event){return _vm.removeField(index)}}},[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-delete")])],1)],1)])}),0)])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"font-size-16 font-weight-500 color-custom-blue"},[_vm._v(" All custom fields can be edited and reordered in Settings > Custom Fields. ")])])],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":!_vm.formValid || _vm.formLoading,"loading":_vm.formLoading,"color":"cyan"},on:{"click":_vm.onSubmit}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.formLoading},on:{"click":function($event){return _vm.$emit('close:custom-field', true)}}},[_vm._v(" Close ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }