var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"custom-grey-border remove-border-radius",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline grey lighten-4"},[_c('h3',{staticClass:"font-weight-700 custom-headline color-custom-blue"},[_vm._v(" Payment Information ")])]),_c('v-card-text',{staticClass:"p-6 font-size-16"},[_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('label',{staticClass:"font-size-16"},[_vm._v("Amount Pending")]),_c('v-text-field',{attrs:{"dense":"","color":"cyan","filled":"","type":"number","min":"0","readonly":"","disabled":_vm.pageLoading,"label":"Amount Pending","rules":[
              _vm.validateRules.minLength(
                _vm.paymentInformation.pending,
                'Amount Pending',
                1
              ),
              _vm.validateRules.maxLength(
                _vm.paymentInformation.pending,
                'Amount Pending',
                100
              ) ],"solo":"","flat":"","hide-details":""},model:{value:(_vm.paymentInformation.pending),callback:function ($$v) {_vm.$set(_vm.paymentInformation, "pending", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"paymentInformation.pending"}})],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('label',{staticClass:"font-size-16"},[_vm._v("Amount Received")]),_c('v-text-field',{attrs:{"dense":"","color":"cyan","filled":"","type":"number","min":"0","disabled":_vm.pageLoading,"label":"Amount Received","rules":[
              _vm.validateRules.minLength(
                _vm.paymentInformation.received,
                'Amount Received',
                1
              ),
              _vm.validateRules.maxLength(
                _vm.paymentInformation.received,
                'Amount Received',
                100
              ) ],"solo":"","flat":"","hide-details":""},on:{"keypress":function($event){return _vm.limitDecimal($event, _vm.paymentInformation.received)}},model:{value:(_vm.paymentInformation.received),callback:function ($$v) {_vm.$set(_vm.paymentInformation, "received", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"paymentInformation.received"}})],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('label',{staticClass:"font-size-16"},[_vm._v("Transaction ID")]),_c('v-text-field',{attrs:{"dense":"","color":"cyan","filled":"","disabled":_vm.pageLoading,"label":"Transaction ID","rules":[
              _vm.validateRules.minLength(
                _vm.paymentInformation.transaction_id,
                'Transaction ID',
                1
              ),
              _vm.validateRules.maxLength(
                _vm.paymentInformation.transaction_id,
                'Transaction ID',
                100
              ) ],"solo":"","flat":"","hide-details":""},model:{value:(_vm.paymentInformation.transaction_id),callback:function ($$v) {_vm.$set(_vm.paymentInformation, "transaction_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"paymentInformation.transaction_id"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }